<template>
  <el-drawer append-to-body title="" size="100%" :visible.sync="visible" @close="$emit('update:show', false)" :show-close="true" :show="show">
    <div v-if="isCraftKP" class="content-wrap">
    <div class="content-select">
      <div class="content-main">
        <div class="invoice-buttons">
          <div>
            <!-- <el-button type="default" :loading="billingLoading" @click="handleGoRedNotice">红字信息表</el-button> -->
            <el-tooltip content="请选择要设置折扣的商品明细" placement="top">
              <el-button v-if="hadTdFocus" :type="typeByDiscount" @click="handleDiscount">折扣</el-button>
              <span v-else class="el-button is-disabled">折扣</span>
            </el-tooltip>
            <el-button @click="handleChangeTaxStatus" :type="typeByTaxStatus">{{ hadTax | fmtTaxStatus }} </el-button>
            <el-button type="default" @click="handlePreview">预览</el-button>
            <!-- <el-button type="default" @click="handlePrint" :disabled="billingInvoiceId == ''">打印</el-button> -->
            <el-button type="default" @click="handleClear">清空</el-button>
            <!-- <el-button type="primary" :loading="billingLoading" v-role="'RB-BR-XZFJ'" @click="handleBillingInvoice(false)">开具 </el-button> -->
            <el-button type="primary"  @click="handleSaveApply">保存</el-button>
            <!-- <el-button @click="handleChangeRed" :type="typeByRed">{{ redType | fmtRedType }}</el-button> -->
            <!-- <el-tooltip placement="top" content="仅支持根据当前发票类型查询当前组织及当前组织对应税盘开具的蓝票">
              <el-button @click="queryVisiable = true" type="default">蓝票查询</el-button>
            </el-tooltip> -->
          </div>
          <div>
           
          </div>
        </div>
        <div class="invoice-wrap">
          <div class="invoice-header">
            <div>
              <!-- 多订单合并 -->
              <div class="tag_list" v-if="orderList.length">
                <el-tag size="small" :type="item.type" closable v-for="(item, index) in orderList" :key="index" @click="handleTag('click', item)" @close="handleTag('close', item)">
                  <el-tooltip v-show="item.type === 'info'" class="item" effect="dark" content="此订单已开票，不允许重复开票" placement="top">
                    <span class="tag_txt">{{ item.no }}</span>
                  </el-tooltip>
                  <span class="tag_txt" v-show="item.type !== 'info'">{{ item.no }}</span>
                </el-tag>
              </div>
              <!-- 订单详情 -->
              <OrderManualInvoice :hadTax="hadTax" :visiable="isShowOrderDetail" @closeOrderDetailDialog="closeOrderDetailDialog" :buyerInfo="buyerInfo" :invoiceInfo="invoiceInfo" :order="order" @getMergeGoodsList="getMergeGoodsList" :deleteIndex="deleteIndex" />
              <!-- 扫码待开遮罩层 -->
              <div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0" @click="waitDataVisible = false" v-if="waitDataVisible"></div>
              <span class="invoice-no scan_invoice_popover">
                <div class="cus_popover" v-if="waitDataVisible">
                  <div class="button-wrap">
                    <div>
                      <el-tabs @tab-click="waitStatusChange" v-model="activeName">
                        <el-tab-pane label="未处理" name="WCL"> </el-tab-pane>
                        <el-tab-pane label="已处理" name="YCL" lazy> </el-tab-pane>
                      </el-tabs>
                    </div>
                    <el-button style="margin-left: 60%" type="primary" v-if="activeName === 'WCL'" @click="handleWaitBillClear">清空待开</el-button>
                  </div>
                  <div>
                    <scan-wait-bill-invoice ref="scanWaitComponent" @handleScanWaitInvoice="handleScanWaitInvoice"></scan-wait-bill-invoice>
                  </div>
                </div>
                <!-- <el-button @click="handleShowWaitTitle">扫码待开</el-button> -->
                <!-- <el-button >&nbsp;</el-button> -->
                  <div style="margin-right: 200px"></div>
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                <!-- <el-popover placement="bottom-start" width="700" v-model="waitDataVisible" :append-to-body="false">
                  <div>
                    <div class="button-wrap">
                      <div>
                        <el-tabs @tab-click="waitStatusChange" v-model="activeName">
                          <el-tab-pane label="未处理" name="WCL"> </el-tab-pane>
                          <el-tab-pane label="已处理" name="YCL" lazy> </el-tab-pane>
                        </el-tabs>
                      </div>
                      <el-button style="margin-left: 60%" type="primary" v-if="activeName === 'WCL'" @click="handleWaitBillClear">清空待开</el-button>
                    </div>
                    <div>
                      <scan-wait-bill-invoice ref="scanWaitComponent" @handleScanWaitInvoice="handleScanWaitInvoice"></scan-wait-bill-invoice>
                    </div>
                  </div>
                  <el-button slot="reference" @click="waitStatusQuery(disposeStatus)">扫码待开</el-button>
                </el-popover> -->
                <!-- <el-input type="text" v-model="invoiceInfo.dataId" placeholder="请输入订单编号" maxlength="20" @keyup.enter.native="handleOriginDataNew" clearable></el-input>
                <span class="new_order">
                  <el-tooltip class="item" effect="dark" content="新增订单" placement="bottom"> <i class="el-icon-plus" style="color: #1890ff" @click="addOrder"></i></el-tooltip>
                </span> -->
                <!-- <el-input type="text" v-model="invoiceInfo.dataId" placeholder="请输入订单编号" maxlength="32" @keyup.enter.native="handleOriginData" clearable></el-input> -->
                <!-- <el-input  maxlength="32"></el-input> -->
                <!--                <span class="new_order">-->
                <!--                  <el-tooltip class="item" effect="dark" content="新增订单" placement="bottom"> <i class="el-icon-plus" :style="{ color: invoiceInfo.dataId ? '#1890ff' : '#ccc' }" @click="addOrder"></i></el-tooltip>-->
                <!--                </span>-->
              </span>
            </div>

            <invoice-billing-type ref="billingTypeComponent" v-if="changeType" @changeBillingType="handleChangedRadio" :orgId="orgId" :billing-channel="billingChannel"></invoice-billing-type>
            <div class="invoice-member">
              <div class="info" v-if="elecType == 1">
                <!-- <div>
                  <label>本月总额度：</label>
                  <span>{{ totalAmountLimit }}</span>
                </div>
                <div>
                  <label>可用剩余额度：</label>
                  <span>{{ availableRemainingLimit }}</span>
                </div> -->
              </div>
              <div class="info" v-else>
                <!-- <div>
                  <label>发票代码：</label>
                  <span>{{ inventory.invoiceCode }}</span>
                </div>
                <div>
                  <label>发票号码：</label>
                  <span>{{ inventory.invoiceNo }}</span>
                </div>
                <div>
                  <label>发票剩余：</label>
                  <span>
                    <el-button type="text" @click.prevent="showInvoiceAllowance" style="padding: 0px">{{ inventory.residueQuantity ? inventory.residueQuantity : 0 }}</el-button>
                  </span>
                </div> -->
              </div>
              <div class="refresh">
                <!-- <el-button icon="el-icon-refresh" circle @click="handleInitInventoryClick" :loading="inventoryLoading"></el-button> -->
              </div>
            </div>
          </div>
          <div class="invoice-nav">
            <div class="invoice-buyer-info">
              <span class="buyer-tag">购买方</span>
              <invoice-open-user-info class="user-info-form" v-if="!isRepurchase" :buyer-info="buyerInfo" :billing-type="invoiceInfo.billingType" @fitBuyerInfo="fitBuyerInfo" @disableClick="false"></invoice-open-user-info>
              <invoice-seller-info class="user-info-form" v-else :info="sellerInfo" :billing-type="invoiceInfo.billingType" :org-id="orgId"></invoice-seller-info>
           
            </div>
            <div class="invoice-user-address">
              <invoice-open-user-address ref="invoiceOpenUserAddress" :buyerEmail="buyerEmail" :equipment="equipment" @handleChangedRadio="handleChangedRadio" @handleDZPPcheck="handleDZPPcheck"></invoice-open-user-address>
            </div>
          </div>
          <div class="interval"></div>
          <div class="invoice-goodsList">
            <invoice-open-goods-list ref="invoiceGoodsList" :tax-status="hadTax" :goods-list="goodsList" :biz-type="redType ? 'manual' : 'red'" :goods-index="goodsIndex" @handleFocusTd="handleFocusTd" @handleInsertMerchandise="handleInsertMerchandise" @handleDiscount="handleDiscount" @changeIndex="changeIndex" @dataSelect="handleCurrentGoods" @handleShowGoods="handleShowGoods" @nameBlur="handleMerchandiseNameBlur" @reviewInvoice="handleReviewInvoice"></invoice-open-goods-list>
          </div>
          <div class="interval"></div>
          <div class="invoice-aside">
            <div class="invoice-seller-info">
              <span class="seller-tag">销售方</span>
              <invoice-open-user-info class="seller-info" v-if="isRepurchase" :buyer-info="buyerInfo" :billing-type="invoiceInfo.billingType" @fitBuyerInfo="fitBuyerInfo" ></invoice-open-user-info>
              <invoice-seller-info class="seller-info" v-else :info="sellerInfo" :billing-type="invoiceInfo.billingType" :org-id="orgId"></invoice-seller-info>
            </div>
            <div class="invoice-seller-ext">
              <span class="seller-tag">备注</span>
              <div v-if="redType">
                <textarea v-model="invoiceInfo.remark" maxlength="140" cols="30" rows="10"></textarea>
              </div>
              <div class="red-invoice-remark" v-else>
                <div class="code-no">
                  <div v-if="invoiceInfo.billingType == 'QDZP' || invoiceInfo.billingType == 'QDZP'">
                    <span>对应正数发票代码:</span>
                    <el-input v-model="invoiceInfo.originInvoiceCode" maxlength="12" />
                  </div>
                  <div v-else>
                    <label>对应正数发票代码:</label>
                    <el-input v-model="invoiceInfo.originInvoiceCode" maxlength="12" />
                  </div>
                  <div>
                    <label>号码:</label>
                    <el-input v-model="invoiceInfo.originInvoiceNo" maxlength="10" />
                  </div>
                </div>
                <div v-if="invoiceInfo.billingType == 'Zzzp'" class="red-area">
                  <label>红字信息表编号:</label>
                  <el-input v-model="invoiceInfo.redNoticeNo" maxlength="20" />
                </div>
                <span class="hr"></span>
                <textarea id="textarea" v-model="invoiceInfo.remark" maxlength="140" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>
          <div class="invoice-foot">
            <div class="foot-item" v-if="invoiceInfo.billingType == 'QDZP' || invoiceInfo.billingType == 'QDPP' ? false : true">
              <label>收款人:</label>
              <el-input v-model="invoiceInfo.payee" placeholder="请输入收款人姓名"></el-input>
            </div>
            <div class="foot-item auditor" v-if="invoiceInfo.billingType == 'QDZP' || invoiceInfo.billingType == 'QDPP' ? false : true">
              <label>复核人:</label>
              <el-input v-model="invoiceInfo.auditor" placeholder="请输入复核人姓名"></el-input>
            </div>
            <!-- <div class="foot-item payer">
              <label>开票人：</label>
              <span>{{ invoiceInfo.payer }}</span>
            </div> -->
            <!-- <div class="foot-item setting">
              <el-button type="text" @click="handleInvoiceOpenPersonSetting">设置</el-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 确认将销售方信息设为默认开票信息弹窗 -->
    <el-dialog append-to-body title="提示" :visible.sync="setSellerInformationConfirmOpen" width="30%" @close="setSellerInformationConfirmOpen = false">
      <div style="margin-bottom: 30px">
        <span style="font-size: medium">是否将当前销售方信息设为默认开票信息</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setSellerInformationConfirmOpen = false">取 消</el-button>
        <el-button type="primary" @click="handleSetSellerInformation">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 编辑销售方信息弹窗 -->
    <el-dialog append-to-body title="销售方信息修改" :visible.sync="updateSellerInfo" width="480px" @close="updateSellerInfoCancel">
      <el-form :model="sellerInfo" status-icon ref="ruleForm" label-width="140px">
        <el-row>
          <el-col :span="18">
            <el-form-item label="地址、电话:">
              <el-input v-model="sellerInfo.sellerAddressTel" maxlength="200" style="width: 300px" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18" style="margin-top: 20px">
            <el-form-item label="开户行及账号:">
              <el-input v-model="sellerInfo.sellerBankAccount" maxlength="200" style="width: 300px" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px">
        <el-button @click="updateSellerInfoCancel">取 消</el-button>
        <el-button type="primary" @click="updateSellerInfoSure(sellerInfo)">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 选择商品 -->
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible" @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>
    <!-- 发票开具 -->
    <invoice-bill v-if="issueVisible" :show.sync="issueVisible" :failRedInvoice="failRedInvoice" :isCraft="isCraft" :invoice="issue" @billing-invoice="handleBillingManualInvoice"></invoice-bill>
    <!-- 发票超限额 -->
    <el-dialog append-to-body title="超限额提醒" :visible.sync="limitVisible" width="510px">
      <div class="limit-dialog">
        <div style="color: red; width: 95%; line-height: 25px">注:当前待开发票未税金额超过税控设备开票限额，点击"确认生成"按钮后，系统将会自动进行超限额拆分处理，拆分多张发票。</div>
      </div>

      <div style="padding-left: 20px; text-align: left; font-size: 14px; margin-top: 15px; border-top: 1px solid #e9e9e9">
        <div style="margin-top: 15px">
          当前设备开具{{ fmtBillingType(limit.billingType) }}限额:
          <span>{{ limit.limit }}</span>
        </div>
        <div style="margin-top: 15px">本批次提交开票的发票:</div>
        <div style="margin-top: 15px">
          未税金额:
          <span>{{ issueData.amount }}</span>
        </div>
        <div style="margin-top: 15px">
          税额:
          <span>{{ issueData.taxAmount }}</span>
        </div>
        <div style="margin-top: 15px">
          价税合计:
          <span>{{ issueData.sumAmount }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="limitVisible = false">取消</el-button>
        <el-button type="primary" @click="splitBillInvoice">确定生成</el-button>
      </div>
    </el-dialog>
    <!-- 开票设置 -->
    <el-dialog append-to-body title="开票设置" class="setting" :visible.sync="settingVisible" width="60%">
      <el-tabs v-model="settingTabsValue">
        <el-tab-pane label="设置开票人" name="BillPersons">
          <billing-open-person-setting @persons-change="handleRefreshBillingConfig" />
        </el-tab-pane>
        <el-tab-pane label="设置开票设备" name="BillEquipment">
          <!-- <billing-equipment-setting @equipment-change="handleEquipmentChange" /> -->
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 新增商品 -->
    <el-dialog append-to-body title="新增" :visible.sync="editVisible" :show-close="false" :destroy-on-close="true" @close="handleCancelEditDialog">
      <merchandise-edit v-if="editVisible" :merchandise="merchandise" :is-retail="true" :content="taxPreferentialContents" scene="Billing" @edit-finish="handleMerchandiseEditFinish" @handleCancelEditDialog="handleCancelEditDialog"></merchandise-edit>
    </el-dialog>
    <!-- 开具提示（存在已开发票） -->
    <el-dialog append-to-body title="是否开具相同的发票" width="418px" :visible.sync="billingAlreadyVisible" :show-close="false">
      <div class="already-dialog">
        <div>
          上一张发票状态为:
          <span class="invoice-status">{{ fmtEnum(invoiceStatus) }}</span>
        </div>
        <div class="tip">若需开具不同信息的发票需手动清空当前页面发票数据</div>
        <div slot="footer" class="already-footer">
          <el-button type="default" @click="billingAlreadyVisible = false">取消</el-button>
          <el-button type="primary" @click="handleBillingInvoice(true)">开具</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 发票打印 -->
    <invoice-print ref="invoicePrint" :invoices="printInvoices"></invoice-print>
    <!-- 新增折扣 -->
    <el-dialog append-to-body title="商品折扣" width="410px" :visible.sync="insertDiscountVisible" :show-close="false">
      <div class="discount-wrap">
        <el-form class="discount-form" ref="discountForm" :rules="discountRules" :model="discountForm" label-width="120px">
          <el-form-item v-if="hadTax" label="金额(含税)">
            <el-input v-model="discountForm.sumAmount" disabled></el-input>
          </el-form-item>
          <el-form-item v-else label="金额(不含税）">
            <el-input v-model="discountForm.amount" disabled></el-input>
          </el-form-item>
          <el-form-item prop="discount" label="折扣率">
            <el-input v-model="discountForm.discount" @input="handleComputedDisCountAmount" v-float="2">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="discountAmount" label="折扣金额">
            <el-input v-model="discountForm.discountAmount" @input="handleComputedDisCountRate" v-float="2"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="discount-footer">
          <el-button type="default" @click="handleCloseDiscount">取消</el-button>
          <el-button type="primary" @click="handleConfirmDiscount">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 删除折扣 -->
    <el-dialog append-to-body :title="deleteGoods ? '删除商品' : '删除折扣'" width="410px" :visible.sync="deletedDiscountVisible" :show-close="false">
      <div class="delete-wrap">
        <div class="delete-content">
          <div v-if="deleteGoods">是否删除当前商品明细</div>
          <div v-else>是否删除当前折扣明细？</div>
          <div v-if="deleteGoods" class="message">删除明细后，下方对应的商品折扣信息也将被删除</div>
          <div v-else class="message">删除折扣明细后，上方对应的一行商品明细将取消设置折扣</div>
        </div>
        <div class="footer">
          <el-button type="default" @click="handleCloseDeleteDialog">取消</el-button>
          <el-button type="primary" @click="handleDeleteDiscount">删除</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 开票返回结果 -->
    <el-dialog append-to-body :title="billingReturnInfo" :visible.sync="billingReturnInfoVisible" @close="closeBillingReturnInfo" width="410px">
      <invoice-billing-return-info :printInfo="printInfo" :billingInfo="billingInfo" :buttonShow="buttonShow" @closeBillingReturnInfo="closeBillingReturnInfo" @navigateToAlready="navigateToAlready" :show.sync="issueVisible"></invoice-billing-return-info>
    </el-dialog>
    <!-- 发票余量信息 -->
    <el-dialog append-to-body title="发票余量信息" :visible.sync="dialogInvoiceAllowance" width="600px" :show-close="false">
      <invoice-allowance-dialog v-if="dialogInvoiceAllowance" :orgId="this.orgId" :equipmentId="this.equipment.id" :billingType="this.invoiceInfo.billingType" :orgName="this.sellerInfo.sellerName" @handleCloseAllowance="handleCloseAllowance"></invoice-allowance-dialog>
    </el-dialog>
    <!-- 蓝票查询 -->
    <el-dialog append-to-body :visible.sync="queryVisiable" title="蓝票发票信息查询" width="968px" :show-close="false">
      <query-invoice :billingType="billingInvoice.billingType" :orgId="orgId" @invoice-click="handleRowClick"></query-invoice>
    </el-dialog>
    <!-- 发票预览 -->
    <ApplyInvoicePreview :show.sync="invoicePreviewDialogYL" :YL="YL" :YLSD="YLSD" :invoicedetailData="goodsList" :invoiceInfoData="{ ...invoiceInfo, ...buyerInfo, ...buyerEmail }"></ApplyInvoicePreview>
    <!-- <taxBureauAuthDialog :visible.sync="authDialogVisible"></taxBureauAuthDialog> -->
  </div>
  
  <router-view v-else></router-view>
  </el-drawer>
</template>
<script>
import QueryInvoice from '@/views/invoice-open/invoice-issue/retail/components/QueryInvoice.vue';
import InvoiceOpenUserInfo from '@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenUserInfo';
import ScanWaitBillInvoice from '@/views/invoice-open/invoice-issue/retail/components/ScanWaitBillInvoice';
import InvoiceOpenUserAddress from '@/views/invoice-open/invoice-issue/retail/components/RetailOpenUserAddress';
import InvoiceOpenGoodsList from '@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenGoodsList';
import MerchandiseListDialog from '@/views/invoice-open/invoice-issue/components/MerchandiseListDialog';
import InvoiceBill from '@/views/invoice-open/components/invoice-bill';
import BillingOpenPersonSetting from '@/views/invoice-open/invoice-issue/components/BillingOpenPersonSetting';
import MerchandiseEdit from '@/views/system/merchandise/MerchandiseEdit';
import BillingEquipmentSetting from '@/views/invoice-open/invoice-issue/components/BillingEquipmentSetting';
import {getOrgOwnConfig, setOrgOwnConfig} from '@/service/system/config';
import InvoiceAllowanceDialog from '@/views/invoice-open/invoice-issue/components/InvoiceAllowanceDialog';
import InvoiceBillingReturnInfo from '@/views/invoice-open/invoice-issue/components/InvoiceBillingReturnInfo';
import InvoiceBillingType from '@/views/invoice-open/invoice-issue/components/InvoiceBillingType';
import InvoiceSellerInfo from '@/views/invoice-open/invoice-issue/components/InvoiceSellerInfo';
import InvoicePrint from '@/views/invoice-open/components/invoice-print';
import OrderManualInvoice from '@/views/invoice-open/invoice-issue/retail/components/OrderManualInvoice';
import ApplyInvoicePreview from '@/views/sal/apply/components/ApplyInvoicePreview';
import { getOriginData, getEquipmentByOrgId, manualInvoiceIssue, queryRemoteData, getCurrentUserDefaultConfig, getBillingInvoice, getInvoiceItemNumbers, getCreditLimitByOrganization, getCreditByOrgId,saveApplyInvBill } from '@/service/invoice-open';
import { checkInvoiceLimit, getInventory, manualSplitInvoiceLimit } from '@/service/sal/invoice.js';
import { selectOrganizationTaxpayer } from '@/service/organization';
import taxBureauAuthDialog from "@/components/taxBureauAuthDialog.vue";
import {DataUtil} from '@/util/dataUtil';
// import CustomerSelectDialog from './components/CustomerSelectDialog.vue';

export default {
  name: 'InvoiceApply',
  components: {
    // InvoicePreview,
    InvoiceBillingReturnInfo,
    InvoiceOpenUserInfo,
    InvoiceOpenUserAddress,
    InvoiceOpenGoodsList,
    MerchandiseListDialog,
    InvoiceBill,
    BillingOpenPersonSetting,
    BillingEquipmentSetting,
    MerchandiseEdit,
    InvoiceAllowanceDialog,
    InvoiceBillingType,
    InvoiceSellerInfo,
    InvoicePrint,
    QueryInvoice,
    ScanWaitBillInvoice,
    OrderManualInvoice,
    taxBureauAuthDialog,
    ApplyInvoicePreview
    // CustomerSelectDialog
  },
  filters: {
    fmtTaxStatus(val) {
      return val ? '含税' : '不含税';
    },
    fmtRedType(val) {
      return val ? '正数' : '负数';
    },
    fmtLongValue(val) {
      if (val) return val.length > 24 ? val.substring(0, 24) + '...' : val;
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    YL: {
      type: String,
      default: ''
    },
    invoiceInfoData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    invoicedetailData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    YLSD: {
      type: String,
      default: ''
    },
    pictLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 是否收购类型发票
    isRepurchase() {
      return this.invoiceInfo.billingType === 'SGDP' || this.invoiceInfo.billingType === 'SGZP';
    },
    // 含税按钮状态
    typeByTaxStatus() {
      return this.hadTax ? 'default' : 'primary';
    },
    // 红蓝票按钮状态
    typeByRed() {
      return this.redType ? 'default' : 'primary';
    },
    // 折扣按钮状态
    typeByDiscount() {
      return this.discountStatus ? 'primary' : 'default';
    }
  },
  watch: {
    show() {
      this.visible = this.show;
    },
    '$store.state.invoiceIssue.CurrentOrganization': {
      handler(value) {
        if (value !== 0) {
          this.fitSellerInfo(value, () => {
            this.handleGetSellerSetting(value);
          });
          this.sellerInfoUpdate.orgId = value;
          this.merchandise.orgId = value;
          this.changeType = false;
          this.$nextTick(() => {
            this.changeType = true;
          });
        }
      },
      immediate: true
    },
    '$route.path': {
      handler() {
        console.log(this.$route.path, '-----this.$route.path------');
        this.isCraftKP = this.$route.path == '/open/issue';
      }
    },
    invoiceInfoData: {
      handler(val, oal) {
        this.id=val.id
        this.buyerInfo.buyerName = val.buyerName;
        this.buyerInfo.buyerTaxNo = val.buyerTaxNo;
        this.buyerInfo.buyerAddressTel = val.buyerAddressTel;
        this.buyerInfo.buyerBankAccount = val.buyerBankAccount;
        this.buyerEmail.receiveEmail = val.receiveEmail;
        this.invoiceInfo.remark = val.remark;
        this.sellerInfo.sellerName = val.sellerName;
        this.sellerInfo.sellerTaxNo = val.sellerTaxNo;
        this.sellerInfo.sellerAddressTel = val.sellerAddressTel;
        this.sellerInfo.sellerBankAccount = val.sellerBankAccount;
        this.sellerInfo.specialBizType = val.specialBizType;
        this.sellerInfo.realEstateAddressDetail = val.realEstateAddressDetail;
        this.sellerInfo.leaseDateStart = val?.leaseDateStart?.slice(0, 10);
        this.sellerInfo.leaseDateEnd = val?.leaseDateEnd?.slice(0, 10);
        this.sellerInfo.transRegionalMark = val.transRegionalMark;
        this.sellerInfo.realEstateCertificate = val.realEstateCertificate;
      }
    },
        invoicedetailData: {
      handler(val) {
        this.goodsList = val;
      },
      immediate: true
    },
  },
  data() {
    return {
      isCraft: false,
      isCraftKP: true,
      authDialogVisible: false,
      failRedInvoice: 'failRedInvoice',
      isShowOrderDetail: false,
      invoicePreviewDialogYL: false,
      sellerInfoUpdate: {
        oldSellerAddressTel: '',
        oldSellerBankAccount: '',
        orgId: ''
      },
      elecType: 0,
      totalAmountLimit: '', //本月总额度
      availableRemainingLimit: '', //可用剩余额度
      setSellerInformationConfirmOpen: false,
      updateSellerInfo: false,
      changeType: true, // 切换发票类型
      orgId: '', //提交申请的开票组织id
      kpOrgId: '',//开票组织
      hadTax: true, // 含税状态
      redType: true, //  切换红蓝票
      inventoryLoading: false, // 刷新库存loading
      discountStatus: false, // 折扣状态
      insertDiscountVisible: false,
      deletedDiscountVisible: false,
      dialogInvoiceAllowance: false,
      hadTdFocus: false, // 表格被选中状态
      manual: 'manual',
      billingType: '', //发票开具类型
      invoiceTitle: '',
      billingChannel: '', // 开票方式
      billingLoading: false,
      dialogGoodsListVisible: false,
      issueVisible: false,
      queryVisiable: false, // 查询蓝票弹窗
      limitVisible: false,
      billingAlreadyVisible: false, // 已有开票信息弹窗
      printInvoices: [], //打印发票
      invoiceStatus: '',
      limit: {},
      goodsIndex: 0, //商品标识符
      currentGoodsIndex: 0, //当前选中商品标识符
      goodsList: [],
      buyerInfo: {
        buyerName: '',
        buyerTaxNo: '',
        buyerAddressTel: '',
        buyerBankAccount: ''
      },
      sellerList: this.$store.state.user.organizations,
      sellerInfo: {
        sellerName: '',
        sellerTaxNo: '',
        sellerAddressTel: '',
        sellerBankAccount: ''
      },
      buyerEmail: {
        receiveEmail: '',
        expressPhone: '',
        expressContact: '',
        expressAddress: ''
      },
      billingTypes: {
        Dzpp: '电普',
        DZZP: '电专',
        Zzpp: '纸普',
        Zzzp: '纸专'
      },
      invoicePrice: {}, //发票金额相关
      invoiceInfo: {
        dataId: '', //发票编号（订单编号）
        billingType: '', //开具类型(电子发票/普通发票)
        payee: '', //收款人
        auditor: '', //复核人
        payer: '', //开票人
        remark: '', //备注
        redNoticeNo: '',
        originInvoiceCode: '',
        originInvoiceNo: ''
      },
      billingInvoiceId: '',
      billingInvoice: {},
      equipment: {},
      issue: {},
      issueData: {},
      DZPPCheck: false,
      forceUnlock: false,
      forceUnlockData: {},
      invoiceOpenPersonSettingVisible: false,
      editVisible: false,
      taxPreferentialContents: [],
      settingVisible: false,
      settingTabs: [
        { code: 'BillPersons', title: '设置开票人' },
        { code: 'BillEquipment', title: '设置开票设备' }
      ],
      settingTabsValue: 'BillPersons',
      merchandise: {
        orgId: this.$store.state.invoiceIssue.CurrentOrganization,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      },
      inventory: {
        residueQuantity: 0
      },
      discountForm: {
        amount: null,
        sumAmount: null,
        discount: null,
        hadTax: false,
        discountAmount: null
      },
      deleteGoods: false, //是否删除折扣商品
      discountRules: {
        discount: [{ required: true, message: '请输入折扣率', trigger: 'blur' }],
        discountAmount: [{ required: true, message: '请输入折扣金额', trigger: 'blur' }]
      },
      billingReturnInfo: '',
      billingReturnInfoVisible: false,
      printInfo: '',
      billingInfo: '',
      buttonShow: false,
      activeName: 'WCL',
      waitData: [],
      waitDataVisible: false,
      disposeStatus: 'WCL',
      existInvoiceInfo: false,
      orderList: [],
      order: {},
      deleteIndex: 0,
      orderGoodsList: [],
      submitStatus: 0,
      id:undefined,
      visible:false
    };
  },
  created() {
    //this.receivedMsg();
    this.handleChangedRadio('Dzpp');
    this.getDicts('o-merchandise-tax').then((res) => (this.taxPreferentialContents = res.data));
  },
  mounted() {
    // 初始化开票信息
    // this.handleRefreshBillingConfig();
    this.$bus.$on('handlerInvoiceData', (data) => {
      this.issue = data;
    });
  },
  methods: {
    async getCreditByOrgId() {
      let res = await getCreditByOrgId({orgId:this.orgId,username:localStorage.getItem("eleAccount")});
      if (res.success) {
        this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
        this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
      }
    },
    async getCreditLimitByOrganization() {
      let param={
        "username":localStorage.getItem("eleAccount"),
        "orgId":this.orgId
      }
      debugger
      let res = await getCreditLimitByOrganization(param);
      console.log(res, 'res');
      if (res.success) {
        if(res.data!=null){
          debugger
          if(res.data.resultcode=='302'){
              this.authDialogVisible=true
          }
          else{
            this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
            this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
          }
        }
        
      }
    },
    getMergeGoodsList({ goods, orderNo }) {
      let addOrder = true;
      this.orderList.forEach((item) => {
        if (item.no == orderNo) {
          addOrder = false;
        }
      });
      if (!addOrder) return;
      const tmpArr = [...this.goodsList, ...goods];
      let mapArr = new Map();
      for (let item of tmpArr) {
        mapArr.set(item.index, item);
      }

      let orderTag = {
        no: orderNo,
        status: true,
        type: 'primary',
        items: JSON.parse(JSON.stringify([...goods]))
      };

      this.orderList = [...this.orderList, orderTag];

      this.goodsList = JSON.parse(JSON.stringify([...mapArr.values()]));

      this.goodsList = this.goodsList.filter((item) => {
        if (item.merchandiseName) {
          return item;
        }
      });

      this.toast('订单数据已自动带入', 'success');
    },
    handleTag(e, item) {
      if (e === 'click') {
        this.order = item;
        this.isShowOrderDetail = true;
      } else if (e === 'close') {
        this.orderList = this.orderList.filter((i) => i.no != item.no);
        this.goodsList = this.goodsList.filter((i) => i.no != item.no);
      }
    },
    closeOrderDetailDialog(e) {
      this.isShowOrderDetail = e;
    },
    // 收到消息
    receivedMsg() {
      // this.$options.sockets.onmessage = ({ data }) => {
      //   console.log(data);
      //   this.toast(data);
      // };
    },
    // 点击刷新额度
    async handleInitInventoryClick() {
      if (!this.invoiceInfo.billingType) return;
      this.inventory = {
        residueQuantity: 0
      };
      this.inventory.invoiceCode = '--';
      this.inventory.invoiceNo = '--';
      this.totalAmountLimit = '--'; //本月总额度
      this.availableRemainingLimit = '--'; //可用剩余额度
      if (this.orgId) {
        if (this.elecType == 1) {
          this.getCreditLimitByOrganization();
        } else {
          this.inventoryLoading = true;
          const { success, data, message } = await getInventory({
            orgId: this.orgId,
            equipmentId: this.equipment.id,
            billingType: this.invoiceInfo.billingType
          });
          this.inventoryLoading = false;
          if (success) {
            debugger
            if (data.data.resultCode == '302') {
              this.authDialogVisible=true
              
            }   else{
              this.inventory.residueQuantity = data.residueQuantity ? data.residueQuantity : 0;
              this.inventory.invoiceCode = data.invoiceCode ? data.invoiceCode : '--';
              this.inventory.invoiceNo = data.invoiceNo ? data.invoiceNo : '--';
              }
          } else {
            this.toast(message, 'error');
          }
        }
      }
    },
    // 初始化库存
    async handleInitInventory() {
      if (!this.invoiceInfo.billingType) return;
      this.inventory = {
        residueQuantity: 0
      };
      this.inventory.invoiceCode = '--';
      this.inventory.invoiceNo = '--';
      this.totalAmountLimit = '--'; //本月总额度
      this.availableRemainingLimit = '--'; //可用剩余额度
      if (this.orgId) {
        if (this.elecType == 1) {
          this.getCreditByOrgId();
        } else {
          this.inventoryLoading = true;
          const { success, data, message } = await getInventory({
            orgId: this.orgId,
            equipmentId: this.equipment.id,
            billingType: this.invoiceInfo.billingType
          });
          this.inventoryLoading = false;
          if (success) {
            if (data) {
              this.inventory.residueQuantity = data.residueQuantity ? data.residueQuantity : 0;
              this.inventory.invoiceCode = data.invoiceCode ? data.invoiceCode : '--';
              this.inventory.invoiceNo = data.invoiceNo ? data.invoiceNo : '--';
            }
          } else {
            this.toast(message, 'error');
          }
        }
      }
    },
    //查询组织信息是否修改
    handleGetSellerSetting(value) {
      let params = {
        orgId: value,
        configs: { BILL_SELLER_BA: '', BILL_SELLER_AT: '' }
      };
      console.log('获取用户配置销方信息...');
      getOrgOwnConfig(params).then((res) => {
        console.log('获取用户配置销方信息！', JSON.stringify(res));
        if (res.success) {
          let configs = res.data.configs;
          if (configs['BILL_SELLER_BA']) {
            this.sellerInfo.sellerBankAccount = configs['BILL_SELLER_BA'];
          }
          if (configs['BILL_SELLER_AT']) {
            this.sellerInfo.sellerAddressTel = configs['BILL_SELLER_AT'];
          }
        }
      });
    },
    //设置用户默认销方信息
    handleSetSellerInformation() {
      const params = {
        configs: {
          BILL_SELLER_AT: this.sellerInfo.sellerAddressTel,
          BILL_SELLER_BA: this.sellerInfo.sellerBankAccount
        },
        orgId: this.sellerInfoUpdate.orgId
      };
      setOrgOwnConfig(params).then((res) => {
        if (res.success) {
          this.setSellerInformationConfirmOpen = false;
          this.toast('修改成功', 'success');
        } else {
          this.setSellerInformationConfirmOpen = false;
          this.sellerInfo.sellerAddressTel = this.sellerInfoUpdate.oldSellerAddressTel;
          this.sellerInfo.sellerBankAccount = this.sellerInfoUpdate.oldSellerBankAccount;
          this.toast('修改失败', 'error');
        }
      });
    },
    //取消修改弹窗
    updateSellerInfoCancel() {
      if (!this.setSellerInformationConfirmOpen) {
        this.sellerInfo.sellerAddressTel = this.sellerInfoUpdate.oldSellerAddressTel;
        this.sellerInfo.sellerBankAccount = this.sellerInfoUpdate.oldSellerBankAccount;
      }
      this.updateSellerInfo = false;
    },
    updateSellerInfoSure() {
      if (this.sellerInfo.sellerAddressTel == this.sellerInfoUpdate.oldSellerAddressTel && this.sellerInfo.sellerBankAccount == this.sellerInfoUpdate.oldSellerBankAccount) {
        this.updateSellerInfo = false;
        this.toast('与原信息一致，无需修改！', 'error');
        return;
      }
      this.updateSellerInfo = false;
      this.setSellerInformationConfirmOpen = true;
    },
    handleEquipmentChange(cont) {
      debugger
      // console.log("默认设备发生变化，根据OrgId判断是否重新获取当前用户的默认信息...",cont)
      if (this.orgId == cont.orgId) {
        getEquipmentByOrgId({ orgId: this.orgId }).then((res) => {
          const { success, data } = res;
          if (success) {
            this.equipment = data;
            this.billingChannel = data.billingChannel;
            getCurrentUserDefaultConfig().then((res) => {
              if (res.success && res.data != null) {
                this.invoiceInfo.payee = res.data.payee;
                this.invoiceInfo.payer = res.data.payer;
                this.invoiceInfo.auditor = res.data.auditor;
              }
            });
          }
        });
      }
    },
    handlePreview() {
      this.invoicePreviewDialogYL = true;
      this.YL = 'YL';
      if (this.invoiceInfo.billingType == 'QDZP' || this.invoiceInfo.billingType == 'QDPP') {
        this.YLSD = 'YLSD';
      } else {
        this.YLSD = '';
      }
    },
    // 打印发票
    async handlePrint() {
      // 校验发票开具状态
      const { success, data } = await getBillingInvoice(this.billingInvoiceId);
      if (success) {
        if (data.invoiceStatus == 'KJZ') {
          this.toast('当前发票开具中，请您稍后再试！', 'error');
          return;
        }
        if (data.invoiceStatus == 'KJSB') {
          this.toast('发票开具失败无法执行打印操作！', 'error');
          return;
        }
        this.billingInvoice = data;
        this.printInvoices.splice(0, this.printInvoices.length, data);
        this.$refs.invoicePrint.openPrint();
      }
    },
    // 发票信息清空
    handleClear() {
      this.issue = {};
      this.billingInvoiceId = '';
      // 购方信息
      this.buyerInfo = {
        buyerName: '',
        buyerTaxNo: '',
        buyerAddressTel: '',
        buyerBankAccount: '',
        sfzsgmfdzdh:false,
        sfzsgmfyhzh:false
      };
      // 购方交付信息
      this.$refs.invoiceOpenUserAddress.resetForm();
      // 票面相关
      this.invoiceInfo.dataId = '';
      this.invoiceInfo.remark = '';
      this.DZPPCheck = false;
      // 商品列表
      this.goodsList.splice(0, this.goodsList.length);
      this.orderList = [];
      this.goodsIndex = 0;
      this.$nextTick(() => {
        // 清空下标集合
        this.$refs.invoiceGoodsList.resetLowPowers();
        this.$refs.invoiceGoodsList.insertGoods();
      });
      this.handleInitInventory();
    },
    handleRowClick() {
      this.queryVisiable = false;
      this.initCopyData();
    },
    // 初始化复制信息
    initCopyData() {
      if (this.$store.state.invoiceIssue.copyInvoice.id) {
        const copyData = DataUtil.clone(this.$store.state.invoiceIssue.copyInvoice);

        // 购方信息
        for (let b in this.buyerInfo) {
          this.buyerInfo[b] = copyData[b];
        }
        // 购方交付信息
        for (let e in this.buyerEmail) {
          this.buyerEmail[e] = copyData[e];
        }
        // 票面相关
        this.invoiceInfo.dataId = copyData['dataId'];
        this.invoiceInfo.remark = copyData['remark'];
        this.handleChangedRadio(copyData['billingType']);
        this.$refs.billingTypeComponent.changeBilling(copyData['billingType']);
        // 原蓝票代码号码
        this.invoiceInfo.originInvoiceCode = copyData['invoiceCode'];
        this.invoiceInfo.originInvoiceNo = copyData['invoiceNo'];
        this.invoiceInfo.redNoticeNo = copyData['redNoticeNo'];
        // 电票邮箱校验 - 复制过来默认通过，修改后重新触发校验
        this.DZPPCheck = /D/.test(this.invoiceInfo.billingType) ? true : false;
        // 商品列表
        this.goodsList = [];
        copyData.items.forEach((val, index) => {
          val['index'] = index;

          if (val.taxPreferentialContent == '免税' || val.taxPreferentialContent == '不征税') {
            val['taxRateModel'] = val.taxPreferentialContent;
            val['taxAmount'] = '***';
          } else {
            val['taxRateModel'] = val.taxRate;
          }
          // 折扣行锁定
          if (val.itemProperty == '1' || val.itemProperty == '2') {
            val['disabled'] = true;
          }

          val['merchandiseName'] = `*${val.taxClassName ? val.taxClassName : ''}*${val.merchandiseName}`;
          this.goodsList.push(val);
        });
        this.$refs.invoiceGoodsList.handleCopyLowPowers(this.goodsList.map((i) => i.index));
        this.goodsIndex = copyData.items.length;

        // 焦点表格第一行第2列-第一个商品的规格型号
        this.$refs.invoiceGoodsList.focusSpec();

        // 清空缓存
        this.$store.commit('invoiceIssue/clearCopyInvoice');
      }
    },
    // 选取购方信息
    fitBuyerInfo(item) {
      this.buyerInfo.buyerAddressTel = item.addressPhone;
      this.buyerInfo.buyerName = item.name;
      this.buyerInfo.buyerTaxNo = item.taxCode;
      this.buyerInfo.buyerBankAccount = item.bankAccount;
    },
    // 选取销方信息
    fitSellerInfo(originalOrgId, callback) {
      // 设置组织
      this.orgId = originalOrgId;

      // 纳税信息
      console.log('获取纳税人的信息...');
      selectOrganizationTaxpayer(originalOrgId).then((oRes) => {
        console.log('获取纳税人的信息结束！', JSON.stringify(oRes));
        if (oRes.success) {
          // 赋值纳税信息
          let taxpayer = oRes.data;
          this.kpOrgId=taxpayer.orgId
          this.sellerInfo.sellerName = taxpayer.name;
          this.sellerInfo.sellerTaxNo = taxpayer.taxNo;
          this.sellerInfo.sellerAddressTel = taxpayer.addressTel;
          this.sellerInfo.sellerBankAccount = taxpayer.bankAccount;
          // 设备信息
          // getEquipmentByOrgId({ orgId: originalOrgId }).then((eRes) => {
          //   if (eRes.success) {
          //     // 赋值设备信息
          //     this.equipment = eRes.data;
          //     this.billingChannel = eRes.data.billingChannel;
          //     this.elecType = eRes.data.elecType;

          //     this.$bus.$emit('elecType', this.elecType);
          //     this.handleInitInventory();

          //     // 更新开票信息
          //     this.handleRefreshBillingConfig();
          //   } else {
          //     this.equipment = {};
          //     this.billingChannel = '';

          //     this.toast(eRes.message, 'error');
          //   }
          // });
        }
        if (callback) callback();
      });
    },
    // 构建发票参数
    async handleBillingInvoice(val) {
      // 开票参数校验
      const checker = this.checkInvoiceInfo();
      if (!checker) return;

      // 重复开具
      if (val) this.billingInvoiceId = 0;

      // 已有开票信息
      if (this.billingInvoiceId) {
        const { success, data } = await getBillingInvoice(this.billingInvoiceId);
        if (success) {
          // 确认获取到已开发票信息
          if (data?.id) {
            this.invoiceStatus = data.invoiceStatus;
            this.billingAlreadyVisible = true;
            return;
          }
        }
      }

      this.$refs.invoiceGoodsList.computedInvoicePrice();
      // 待开发票
      let arr = JSON.parse(JSON.stringify(this.goodsList)).map((i) => {
        i.merchandiseName = i.merchandiseName.split('*').splice(2).join('*');

        // 特殊税率折扣行
        if (this.billingChannel != 'HX-FWQ-JSP' && i.itemProperty == '1') {
          i.isTaxPreferential = null;
          i.taxPreferentialContent = null;
        }

        return i;
      });
      // var isShowAddr=this.sellerInfo.isShowAddr;
      // var remarks="";
      // debugger
      // if(isShowAddr=='Y'){
      //   remarks="销方地址电话："+this.sellerInfo.sellerAddressTel+";"
      // }
      // debugger
      // var isShowBuyerAddr=this.buyerInfo.showBuyerAddr
      // if(isShowBuyerAddr){
      //   remarks=remarks+"购方地址电话："+this.buyerInfo.buyerAddressTel+";"
      // }
      // this.invoiceInfo.remark=remarks+this.invoiceInfo.remark
      this.issue = {
        orgId: this.orgId,
        billingType: this.billingType, //开具类型
        billingChannel: this.billingChannel,
        // ...this.buyerInfo, //购方信息
        ...this.buyerEmail, //邮寄相关
        ...this.sellerInfo, //销方信息,
        ...this.invoiceInfo, //票面信息
        ...this.invoicePrice //票价相关信息
      };

      this.issue.buyerName = this.buyerInfo.buyerName;
      this.issue.buyerTaxNo = this.buyerInfo.buyerTaxNo;
      this.issue.buyerAddressTel = this.buyerInfo.buyerAddressTel;
      this.issue.buyerBankAccount = this.buyerInfo.buyerBankAccount;
      this.issue.username=localStorage.getItem("eleAccount")
      this.issue.sfzsgmfyhzh=this.buyerInfo.sfzsgmfyhzh
      this.issue.sfzsgmfdzdh=this.buyerInfo.sfzsgmfdzdh
      this.issue.items = arr;
      var proId=localStorage.getItem("projectId");
      if(proId!=null && proId!=""){
        this.issue.projectId=proId
      }


      if (this.redType && this.issue.sumAmount <= 0) {
        this.toast('含税金额不可小于等于0，请重新输入', 'error');
        return;
      }
      if (!this.redType && this.issue.sumAmount >= 0) {
        this.toast('红票含税金额不可大于等于0，请重新输入', 'error');
        return;
      }
      // 免税不征税-重置税率为0 - 校验发票明细行
      this.issue.items.forEach((i) => {
        if (!i.price && i.quantity) {
          this.toast('明细中商品单价为空时，商品数量也必须为空', 'warning');
          return;
        }
        if(i.taxRateModel == '免税' || i.taxRateModel == '不征税'){
          i.isTaxPreferential = 1;
          i.taxPreferentialContent = i.taxRateModel;
        }
        i.taxAmount = i.taxAmount == '***' ? 0 : Number(Number(i.taxAmount).toFixed(2));
        i.taxRate = i.taxRate == '免税' || i.taxRate == '不征税' ? 0.0 : Number(Number(i.taxRate).toFixed(2));
        i.price = Number(Number(i.price).toFixed(2));
        i.price = i.price == 0 ? null : i.price;
        i.unTaxPrice = Number(Number(i.unTaxPrice).toFixed(8));
        i.unTaxPrice = i.unTaxPrice == 0 ? null : i.unTaxPrice;
        i.quantity = Number(Number(i.quantity).toFixed(8));
        i.quantity = i.quantity == 0 ? null : i.quantity;
        i.amount = Number(Number(i.amount).toFixed(2));
        i.sumAmount = Number(Number(i.sumAmount).toFixed(2));
      });

      // 税率为空补0
      if (!this.issue.taxAmount) this.issue.taxAmount = 0;
      this.billingAlreadyVisible = false;
      this.handleCheckLimit();
    },

    // 开票信息校验
    checkInvoiceInfo() {
      // 购方校验
      if (!this.buyerInfo.buyerName) {
        this.toast(`${/SG/.test(this.invoiceInfo.billingType) ? '销' : '购'}方名称不可为空`, 'error');
        return false;
      }
      if (this.invoiceInfo.billingType == 'Zzzp' || this.invoiceInfo.billingType == 'DZZP') {
        if (!(this.buyerInfo.buyerName && this.buyerInfo.buyerTaxNo && this.buyerInfo.buyerAddressTel && this.buyerInfo.buyerBankAccount)) {
          this.toast('当前开票种类必须将“名称/税号/地址电话/开户行及账号”填写完整', 'error');
          return false;
        }
      }
      if (this.invoiceInfo.billingType == 'QDZP') {
        if (!(this.buyerInfo.buyerName && this.buyerInfo.buyerTaxNo)) {
          this.toast('当前开票种类必须将“名称/税号”填写完整', 'error');
          return false;
        }
      }
      // 开票人信息校验
      if (this.invoiceInfo.billingType == 'QDPP' || this.invoiceInfo.billingType == 'QDZP') {
        // if (!(this.buyerInfo.buyerName && this.invoiceInfo.payer)) {
        //   this.toast('请将“开票人”填写完整', 'error');
        //   return false;
        // }
      } else {
        if (!(this.buyerInfo.buyerName && this.invoiceInfo.payee && this.invoiceInfo.payer && this.invoiceInfo.auditor)) {
          this.toast('请将“开票人/审核人/复核人”填写完整', 'error');
          return false;
        }
      }
      // 航信组件金税盘 - 乐税机柜UKEY - 铭柏机柜UKEY
      if (/SG/.test(this.invoiceInfo.billingType) && ['HX-ZJ-JSP', 'LS-JG-UKEY', 'MB-JG-UKEY'].indexOf(this.invoiceInfo.billingChannel) > 0) {
        this.toast('当前开票设备不支持开具收购发票，请重新选择', 'error');
        return false;
      }
      // 明细行校验
      if (this.goodsList.length > 0) {
        const amount = this.goodsList.findIndex((i) => i.sumAmount == '' || i.sumAmount == 0 || i.sumAmount == null);
        if (amount > -1) {
          this.toast(`第${amount + 1}条明细价税合计为空`, 'error');
          return false;
        }
        const index = this.goodsList.findIndex((i) => i.taxClassCode == '' || i.taxClassCode == null);
        if (index > -1) {
          this.toast(`第${index + 1}条明细税收分类编码为空`, 'error');
          return false;
        }
        const i_index = this.goodsList.find((i) => i.price == '' || i.price == 0);
        if (i_index > -1 && (this.goodsList[i_index].quantity != '' || this.goodsList[i_index].quantity != 0)) {
          this.toast(`第${i_index + 1}条明细中商品单价为空时，商品数量也必须为空`, 'error');
          return false;
        }
      } else {
        this.toast('至少录入一行商品明细', 'error');
        return false;
      }
      // 红票校验
      if (!this.redType) {
        let isSD = this.invoiceInfo.billingType == 'QDZP' || this.invoiceInfo.billingType == 'QDZP';
        if (!this.invoiceInfo.originInvoiceCode && !isSD) {
          this.toast('开具红票原始发票代码不可为空', 'error');
          return false;
        }
        if (!this.invoiceInfo.originInvoiceNo) {
          this.toast('开具红票原始发票号码不可为空', 'error');
          return false;
        }
        if (this.invoiceInfo.billingType == 'Zzzp' && !this.invoiceInfo.redNoticeNo) {
          this.toast('开具红字专票, 红字信息表编号不可为空', 'error');
          return false;
        }
      }
      return true;
    },
    // 校验发票超限额
    async handleCheckLimit() {
      const { success, data, message } = await checkInvoiceLimit(this.issue);
      debugger
      if (!success) {
        this.toast(message, 'error');
        return;
      }
      if (data.success) {
        this.handleToastIssueResult();
      } else {
        this.limitVisible = true;
        this.limit = data;
        this.issueData = this.issue;
      }
    },
    //超限额拆分开票
    async splitBillInvoice() {
      const { success } = await manualSplitInvoiceLimit(this.issue);
      this.limitVisible = false;
      if (!success) {
        this.toast('请求开票失败', 'error');
        return;
      } else {
        this.toast('请求开票成功，开具发票申请已提交！', 'success');
      }
    },
    // 展开发票结果展示
    handleToastIssueResult() {
      // 非电子发票获取库存
      this.issueVisible = true;
      this.issueData = this.issue;
      this.isCraft = true;
      if (!this.redType) {
        this.issueData.isRedInvoice = true;
      } else {
        this.issueData.isRedInvoice = false;
      }
      console.log(this.issueData, 'this.issueData---------- ');
    },
    // 发票开具
    async handleBillingManualInvoice(printable, back) {
      if (this.submitStatus == 1) {
        this.$message({ type: 'warning', message: '此张发票已经提交开票申请，为避免重开，请到开票记录中查看对应发票的开票状态！' });
      }
      //判断连开带打的标识
      if (printable == 'Y') {
        this.issue.printable = true;
      } else {
        this.issue.printable = false;
      }
      this.issue.sheetType = 'KP';
      this.issue.sheetCode = this.invoiceInfo.dataId;
      this.submitStatus = 1;
      if (this.issue.remark == 'HB#') {
        this.issueVisible = false;
        return;
      }
      if (!this.issue.isRedInvoice) {
        this.issue.toRedRemark = '';
        this.issue.isFastTored = '';
      }
      const { success, data } = await manualInvoiceIssue(this.issue);
      debugger
      if (success) {
        debugger
        this.issue.id=data.invoiceId
        if(data.failedCode=='302'){
          this.submitStatus = 0;
          this.authDialogVisible = true
          this.$bus.$on('elecType', (data) => {
            this.elecType = data;
            if (this.elecType == 1) {
            }
          });
          this.loading=false
          return;
        }
        else{
          this.submitStatus = 0;
          this.billingInvoiceId = data.billingInvoiceId;
          if (data.isSync) {
            if (data.isPrint) {
              if (data.billingState) {
                // console.log('data==>', data)
                this.billingReturnInfo = '发票开具成功！';
                this.billingReturnInfoVisible = true;
                if (data.printState) {
                  this.printInfo = '正在驱动打印机执行打印操作，请稍后......';
                } else {
                  this.printInfo = '驱动打印机失败：根据接口返回原因进行展示';
                }
              } else {
                this.printInfo = '错误原因：根据接口返回原因进行展示！';
                this.billingInfo = '你可以在"开票记录"中重试。';
                this.billingReturnInfo = '发票开具失败！';
                this.buttonShow = true;
                this.billingReturnInfoVisible = true;
              }
            }
          }
          if (/D/.test(this.issue.billingType)) this.handleClear();
          this.orderList = [];
          }
          this.loading=false
        if (back) back(success);
          this.issueVisible = false;
          await this.handleInitInventory();
       
       
      }

    
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      this.invoicePrice = invoicePrice;
    },
    // 切换发票类型
    handleChangedRadio(k) {
      this.invoiceInfo.billingType = k;
      this.invoiceTitle = this.billingTypes[k];
      console.log(this.invoiceTitle, k, 'this.invoiceTitle');
      this.$nextTick(() => {
        this.equipment.id && this.handleInitInventory();
        // 红票业务重置数据
        if (['Zzpp', 'Zzzp', 'SGZP'].indexOf(k) < 0 && !this.redType) {
          this.redType = true;
          this.swapGoodsList();
        }
      });
    },
    async handleOriginDataNew() {
      let no = this.invoiceInfo.dataId;
      let index = this.orderList.find((item) => Number(item.no) == no);
      if (index) {
        this.toast('该订单编号的商品信息已加入列表中！', 'warning');
        return;
      }
      if (this.invoiceInfo.dataId == '' || this.invoiceInfo.dataId == undefined || this.invoiceInfo.dataId == null) {
        this.toast('请输入有效的订单编号！', 'warning');
        return;
      }
      const orderLoading = this.$loading({
        lock: true,
        text: '加载中，请稍后。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { success, data } = await queryRemoteData({
        bizState: '',
        sheetType: 'KP',
        sheetCode: no
      });
      orderLoading.close();
      if (!data.result) {
        this.toast('该订单编号无效', 'warning');
        return;
      }
      if (success) {
        this.handleInvoiceItem(data);
        if (!this.buyerInfo.buyerName) {
          this.handleInvoice(data.data[0]);
        }
        this.toast('订单数据已自动带入', 'success');
      }
    },
    async handleOriginData() {
      if (this.invoiceInfo.dataId == '' || this.invoiceInfo.dataId == undefined || this.invoiceInfo.dataId == null) {
        this.toast('请输入有效的订单编号！', 'warning');
        return;
      }
      const { success, data } = await getOriginData({
        bizState: '',
        sheetType: 'KP',
        sheetCode: this.invoiceInfo.dataId
      });
      if (success && data.items) {
        let bname = this.buyerInfo.buyerName;
        if (bname == undefined || bname == null || bname == '' || bname.trim() == '') {
          this.sellerInfo.sellerName = data.sellerName;
          this.sellerInfo.sellerTaxNo = data.sellerTaxNo;
          this.sellerInfo.sellerAddressTel = data.sellerAddressTel;
          this.sellerInfo.sellerBankAccount = data.sellerBankAccount;

          this.buyerInfo.buyerName = data.buyerName;
          this.buyerInfo.buyerTaxNo = data.buyerTaxNo;
          this.buyerInfo.buyerAddressTel = data.buyerAddressTel;
          this.buyerInfo.buyerBankAccount = data.buyerBankAccount;
        }
        let payee = this.invoiceInfo.payee;
        let payer = this.invoiceInfo.payer;
        let auditor = this.invoiceInfo.auditor;
        this.invoiceInfo = data;
        if (data.payee) {
          this.invoiceInfo.payee = data.payee;
        } else {
          this.invoiceInfo.payee = payee;
        }
        if (data.payer) {
          this.invoiceInfo.payer = data.payer;
        } else {
          this.invoiceInfo.payer = payer;
        }
        if (data.auditor) {
          this.invoiceInfo.auditor = data.auditor;
        } else {
          this.invoiceInfo.auditor = auditor;
        }
        const items = data.items.map((item, index) => {
          item.index = index + 1000000;
          return item;
        });
        this.goodsList = items;
        // 赋值税率
        this.goodsList.forEach((item) => {
          item.taxRateModel = item.taxRate;
          item.merchandiseName = /^\*/.test(item.merchandiseName) ? item.merchandiseName : `*${item.taxClassName ? item.taxClassName : ''}*${item.merchandiseName}`;
        });
        // 赋值开票方式
        let value = this.$refs.billingTypeComponent.billingTypes.filter((item) => item.value == data.billingType);
        this.$refs.billingTypeComponent.handleClick(value[0]);
      }
    },
    handleInvoice(firstInvoice) {
      this.invoiceInfo.payee = firstInvoice.payee ? firstInvoice.payee : this.invoiceInfo.payee;
      this.invoiceInfo.payer = firstInvoice.payer ? firstInvoice.payer : this.invoiceInfo.payer;
      this.invoiceInfo.auditor = firstInvoice.auditor ? firstInvoice.auditor : this.invoiceInfo.auditor;
      this.sellerInfo = firstInvoice;
      this.buyerInfo = firstInvoice;
      this.invoiceInfo = firstInvoice;
      let value = this.$refs.billingTypeComponent.billingTypes.filter((item) => item.value == firstInvoice.billingType);
      this.$refs.billingTypeComponent.handleClick(value[0]);
    },
    handleInvoiceItem(data) {
      let invoices = data.data;
      let goodsLists = [];
      let no = this.invoiceInfo.dataId;

      const itemIdxArr = this.goodsList.map((item) => {
        return item?.index;
      });
      let maxIdx = Math.max.apply(null, itemIdxArr);

      invoices.forEach((singleInvoice) => {
        singleInvoice.items.forEach((item) => {
          item.taxRateModel = item.taxRate;
          item.no = no;
          maxIdx = maxIdx + 9999999;
          item.index = maxIdx;
          goodsLists.push(item);
        });
      });

      if (data.status) {
        this.goodsList = JSON.parse(JSON.stringify([...this.goodsList, ...goodsLists]));
      }

      this.goodsList = this.goodsList.filter((item) => {
        if (item.merchandiseName) {
          return item;
        }
      });

      console.log(this.goodsList, '----this.goodsList-----');

      let orderTag = {
        no: no,
        status: data.status,
        type: data.status ? 'primary' : 'info',
        items: JSON.parse(JSON.stringify([...goodsLists]))
      };
      this.orderList = JSON.parse(JSON.stringify([...this.orderList, orderTag]));
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 新增商品完成
    handleMerchandiseEditFinish(row) {
      this.editVisible = false;
      this.handleCurrentGoods(row);
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 商品选中
    handleCurrentGoods(row) {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent;
        goods['taxRate'] = 0;
      } else {
        goods['taxRate'] = Number(row.taxRate);
        goods['taxRateModel'] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById('input-' + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 加载弹窗
    handleLoading() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading;
    },

    // 电子普票校验
    handleDZPPcheck(status) {
      this.DZPPCheck = status;
    },
    fmtBillingType(val) {
      return this.handleValueToLabel('BillingType', val);
    },
    // 开票人设置
    handleInvoiceOpenPersonSetting() {
      this.settingVisible = true;
    },
    // 初始化当前默认初始化开票人配置
    handleRefreshBillingConfig() {
      console.log('初始化当前默认初始化开票人配置');
      getCurrentUserDefaultConfig().then((res) => {
        if (res.success) {
          let config = res.data;
          // 获取当前默认开票人信息配置
          if (config == null) {
            config = this.equipment;
          }
          this.invoiceInfo.payee = config.payee;
          this.invoiceInfo.payer = config.payer;
          this.invoiceInfo.auditor = config.auditor;
        }
      });
    },
    // 跳转红色通知单
    handleGoRedNotice() {
      if (this.billingChannel != 'NN-JG-JSP' && this.billingChannel != 'HX-FWQ-JSP') {
        this.toast('当前开票方式暂不支持申请红字通知单，请重新设置开票方式', 'warning');
        return;
      }
      //设置本地缓存
      this.isCraft = false;
      localStorage.setItem('BILLING_MODE', this.billingChannel);
      this.$router.push('/open/issue/red-notice');
    },
    handleWaitInvoice() {
      this.waitDataVisible = true;
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
      this.editVisible = !!this.merchandise.merchandiseName;
    },
    // 新增商品信息弹窗 关闭的回调
    handleCancelEditDialog() {
      this.editVisible = false;
      this.merchandise = {
        orgId: null,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      };
      this.$refs.invoiceGoodsList.focusInput(this.currentGoodsIndex);
    },
    fmtEnum(val) {
      return this.handleValueToLabel('InvoiceStatus', val);
    },
    // 切换明细含税状态
    handleChangeTaxStatus() {
      this.hadTdFocus = false;
      this.hadTax = !this.hadTax;
    },
    handleChangeRed() {
      if (['Zzpp', 'Zzzp', 'SGZP', 'QDZP', 'QDPP'].indexOf(this.invoiceInfo.billingType) < 0) {
        return this.toast('当前发票类型不能开具红票', 'warning');
      }
      this.redType = !this.redType;
      this.swapGoodsList();
    },
    // 调换明细正负
    swapGoodsList() {
      this.goodsList.forEach((i) => {
        console.log(i, 'i');
        i.quantity = this.redType ? Math.abs(i.quantity) : -Math.abs(i.quantity);
        i.sumAmount = this.redType ? Math.abs(i.sumAmount) : -Math.abs(i.sumAmount);
        i.taxAmount = this.redType ? Math.abs(i.taxAmount) : -Math.abs(i - i.taxAmount);
        this.$refs.invoiceGoodsList.computedTaxAmount(i);
      });
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == '2';
      this.discountStatus ? (this.deletedDiscountVisible = true) : (this.insertDiscountVisible = true);
    },
    // 计算折扣金额
    handleComputedDisCountAmount() {
      let amount = this.hadTax ? this.discountForm.sumAmount : this.discountForm.amount;
      let rate = this.discountForm.discount * 0.01;
      this.discountForm.hadTax = this.hadTax;
      this.discountForm.discountAmount = Number(amount * rate).toFixed(2);
    },
    // 计算折扣率
    handleComputedDisCountRate() {
      let amount = this.hadTax ? this.discountForm.sumAmount : this.discountForm.amount;
      this.discountForm.hadTax = this.hadTax;
      this.discountForm.discount = Number((this.discountForm.discountAmount / amount) * 100).toFixed(2);
    },
    // 关闭折扣弹窗
    handleCloseDiscount() {
      this.insertDiscountVisible = false;
      this.hadTdFocus = false;
      this.$refs.discountForm.resetFields();
    },
    // 折扣弹窗 - 点击确认
    handleConfirmDiscount() {
      this.$refs.discountForm.validate((val) => {
        if (val) {
          if (!Number(this.discountForm.discountAmount)) {
            this.toast('折扣金额不可为0', 'warning');
            return;
          }
          this.$refs.invoiceGoodsList.insertDiscount(JSON.parse(JSON.stringify(this.discountForm)));
          this.handleCloseDiscount();
          this.toast('设置过折扣信息后商品行/折扣行不可修改，只能删除后再编辑', 'warning');
        }
      });
    },
    // 删除明细
    async handleDeleteDiscount() {
      // 是否删除商品明细
      if (this.deleteGoods) {
        this.$refs.invoiceGoodsList.deleteDiscount(this.currentGoodsIndex, 'goods');
      } else {
        // 下标减1，调用通用删除方法
        this.$refs.invoiceGoodsList.deleteDiscount((this.currentGoodsIndex -= 1), 'discount');
      }
      this.handleCloseDeleteDialog();
    },
    // 删除明细关闭弹窗
    handleCloseDeleteDialog() {
      this.deletedDiscountVisible = false;
      this.hadTdFocus = false;
    },
    closeBillingReturnInfo() {
      this.issueVisible = false;
      this.billingReturnInfoVisible = false;
    },
    navigateToAlready() {
      this.closeBillingReturnInfo();
      this.$emit('navigateToAlready');
    },
    //展示发票余量信息
    showInvoiceAllowance() {
      this.dialogInvoiceAllowance = true;
    },
    //发票余量弹窗关闭
    handleCloseAllowance() {
      this.dialogInvoiceAllowance = false;
    },
    handleShowWaitTitle() {
      this.activeName = 'WCL';
      this.waitDataVisible = !this.waitDataVisible;
    },
    //处理扫码待开发票
    handleScanWaitInvoice(row) {
      this.waitDataVisible = false;
      this.buyerInfo.buyerName = row.buyerName;
      this.buyerInfo.buyerTaxNo = row.buyerTaxNo;
      this.buyerInfo.buyerAddressTel = row.buyerAddressTel;
      this.buyerInfo.buyerBankAccount = row.buyerBankAccount;
      // 赋值开票方式
      // this.redType = true;
      let value = this.$refs.billingTypeComponent.billingTypes.filter((item) => item.value == row.billingType);
      this.$refs.billingTypeComponent.handleClick(value[0]);

      this.$refs.invoiceOpenUserAddress.buyerEmail.receiveEmail = row.email;
      this.$refs.invoiceOpenUserAddress.buyerEmail.receivePhone = row.phone;
    },
    waitStatusChange(targetName) {
      this.disposeStatus = targetName.name;
      this.waitStatusQuery(targetName.name);
    },
    waitStatusQuery(status) {
      this.$refs.scanWaitComponent.disposeStatus = status;
      this.$refs.scanWaitComponent.handleQuery();
    },
    //清空扫码待开列表
    handleWaitBillClear() {
      this.$refs.scanWaitComponent.editScanWaitStatus(0, 'Y');
    },
    //提交开票申请 只申请 不开票
    async handleSaveApply(){
      const checker = this.checkInvoiceInfo();
      if (!checker) return;
      this.$refs.invoiceGoodsList.computedInvoicePrice();
      // 待开发票
      let arr = JSON.parse(JSON.stringify(this.goodsList)).map((i) => {
        i.merchandiseName = i.merchandiseName.split('*').splice(2).join('*');

        // 特殊税率折扣行
        if (this.billingChannel != 'HX-FWQ-JSP' && i.itemProperty == '1') {
          i.isTaxPreferential = null;
          i.taxPreferentialContent = null;
        }

        return i;
      });
      // var isShowAddr=this.sellerInfo.isShowAddr;
      // var remarks="";
      // debugger
      // if(isShowAddr=='Y'){
      //   remarks="销方地址电话："+this.sellerInfo.sellerAddressTel+";"
      // }
      // debugger
      // var isShowBuyerAddr=this.buyerInfo.showBuyerAddr
      // if(isShowBuyerAddr){
      //   remarks=remarks+"购方地址电话："+this.buyerInfo.buyerAddressTel+";"
      // }
      // this.invoiceInfo.remark=remarks+this.invoiceInfo.remark
      this.issue = {
        id:this.id,
        orgId: this.orgId,
        kpOrgId:this.kpOrgId,
        billingType: this.billingType, //开具类型
        billingChannel: this.billingChannel,
        // ...this.buyerInfo, //购方信息
        ...this.buyerEmail, //邮寄相关
        ...this.sellerInfo, //销方信息,
        ...this.invoiceInfo, //票面信息
        ...this.invoicePrice //票价相关信息
      };

      this.issue.buyerName = this.buyerInfo.buyerName;
      this.issue.buyerTaxNo = this.buyerInfo.buyerTaxNo;
      this.issue.buyerAddressTel = this.buyerInfo.buyerAddressTel;
      this.issue.buyerBankAccount = this.buyerInfo.buyerBankAccount;
      this.issue.username=localStorage.getItem("eleAccount")
      this.issue.sfzsgmfyhzh=this.buyerInfo.sfzsgmfyhzh
      this.issue.sfzsgmfdzdh=this.buyerInfo.sfzsgmfdzdh
      this.issue.items = arr;
      var proId=localStorage.getItem("projectId");
      if(proId!=null && proId!=""){
        this.issue.projectId=proId
      }


      if (this.redType && this.issue.sumAmount <= 0) {
        this.toast('含税金额不可小于等于0，请重新输入', 'error');
        return;
      }
      if (!this.redType && this.issue.sumAmount >= 0) {
        this.toast('红票含税金额不可大于等于0，请重新输入', 'error');
        return;
      }
      // 免税不征税-重置税率为0 - 校验发票明细行
      this.issue.items.forEach((i) => {
        if (!i.price && i.quantity) {
          this.toast('明细中商品单价为空时，商品数量也必须为空', 'warning');
          return;
        }
        if(i.taxRateModel == '免税' || i.taxRateModel == '不征税'){
          i.isTaxPreferential = 1;
          i.taxPreferentialContent = i.taxRateModel;
        }
        i.taxAmount = i.taxAmount == '***' ? 0 : Number(Number(i.taxAmount).toFixed(2));
        i.taxRate = i.taxRate == '免税' || i.taxRate == '不征税' ? 0.0 : Number(Number(i.taxRate).toFixed(2));
        i.price = Number(Number(i.price).toFixed(2));
        i.price = i.price == 0 ? null : i.price;
        i.unTaxPrice = Number(Number(i.unTaxPrice).toFixed(8));
        i.unTaxPrice = i.unTaxPrice == 0 ? null : i.unTaxPrice;
        i.quantity = Number(Number(i.quantity).toFixed(8));
        i.quantity = i.quantity == 0 ? null : i.quantity;
        i.amount = Number(Number(i.amount).toFixed(2));
        i.sumAmount = Number(Number(i.sumAmount).toFixed(2));
      });

      // 税率为空补0
      if (!this.issue.taxAmount) this.issue.taxAmount = 0;
      // this.billingAlreadyVisible = false;
      // this.handleCheckLimit();
      const { success, data } = await saveApplyInvBill(this.issue);
      if(success){
        this.issue = {};
        this.$message.success("保存成功")
        // this.show=false
        this.$emit('update:show', false);
        this.$emit('handleCloseEdit')
       
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-wrap {
  background-color: #fff;

  .scan-wait {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.content-select {
  box-shadow: none;

  .select-body {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .drop {
    margin-right: 12px;
  }
}

.content-main {
  margin-top: 0;
  padding: 24px;
  border-color: #9d5224;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .invoice-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
    justify-content: space-between;
  }

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
  }

  ::v-deep .el-radio__inner {
    border: 1px solid #a7643b;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #409eff;
  }

  ::v-deep .el-input__inner:hover {
    border: none;
    border-bottom: 1px solid #1890ff;
    border-radius: 0;
  }

  .interval {
    height: 16px;
    border-left: 1px solid;
    border-right: 1px solid;
  }

  .invoice-wrap {
    max-width: 1200px;
    border: 1px solid #979797;
    padding: 30px 20px 20px 20px;
    background-color: #fffffd;
    color: #9d5224;

    .invoice-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 1.8;
      .tag_list {
        margin-bottom: 10px;
        max-width: 400px;
        height: 26px;
        overflow: hidden;
        border: 1px solid #e4e5e7;
        overflow-y: auto;
        padding: 6px 0;
        border-radius: 4px;
        .el-tag {
          margin: 0 6px 6px;
          cursor: pointer;
          .tag_txt {
            display: inline-block;
            width: 48px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .invoice-no {
        display: inline-block;
        .new_order {
          margin-left: 10px;
          .el-icon-plus {
            font-weight: 900;
            cursor: pointer;
          }
        }
      }

      .invoice-member {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .info {
          min-width: 180px;
          display: flex;
          flex-direction: column;
        }

        .refresh {
          color: #3d94ff;
          font-size: 20px;

          .el-button {
            width: auto;
            height: auto;
            line-height: 1;
          }
        }
      }

      .invoice-title {
        margin-left: -120px;
        font-size: 32px;
        border-bottom: 4px double;

        .title-type {
          color: #1890ff;
        }
      }
    }

    .invoice-nav {
      margin-top: 16px;
      display: flex;
      flex-direction: row;

      .invoice-buyer-info {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        width: 590px;
        min-width: 480px;
        height: 210px;

        .buyer-tag {
          writing-mode: tb;
          letter-spacing: 6px;
          justify-content: center;
          border-right: 1px solid;
          display: flex;
          text-align: center;
          align-items: center;
          width: 30px;
          padding: 0 10px;
          height: 100%;
          line-height: 1.8;
        }
      }

      .invoice-user-address {
        flex: auto;
        height: 210px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-right: 1px solid;
      }
    }

    .invoice-aside {
      display: flex;
      flex-direction: row;

      .invoice-seller-info {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        width: 600px;
        min-width: 600px;
        height: 220px;
      }

      .invoice-seller-ext {
        flex: auto;
        display: flex;
        flex-direction: row;
        height: 220px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-right: 1px solid;

        textarea {
          color: #333333;
          margin: 24px;
          width: 100%;
          border: none;
          outline: none;
          resize: none;
        }
      }

      .seller-tag {
        border-right: 1px solid;
        display: flex;
        text-align: center;
        align-items: center;
        width: 26px;
        padding: 0 10px;
        height: 100%;
        line-height: 1.8;
      }

      .seller-info {
        display: block;
        padding: 24px;

        .seller-item {
          display: flex;
          flex-direction: row;

          .under-row {
            color: #3d94ff;
          }

          .under-row:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .row-first {
          margin-bottom: 24px;
        }
      }

      label {
        display: inline-block;
        min-width: 100px;
      }
    }

    .invoice-foot {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .foot-item {
        line-height: 40px;
        height: 40px;
      }

      .auditor,
      .payer {
        margin-left: 40px;
      }

      .setting {
        margin: 0 0 0 50px;
      }

      label {
        display: inline-block;
        width: 60px;
      }
    }
  }
}

label + span {
  color: #333333;
}

.limit-dialog {
  margin-top: -20px;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;

  .data {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }

  .message {
    padding: 24px 0;
    color: #f5222d;
  }
}

.already-dialog {
  margin-top: -20px;
  padding: 0 24px;
  font-size: 14px;
  text-align: left;

  div {
    .invoice-status {
      font-weight: 500;
      color: #333333;
    }

    padding-bottom: 24px;
  }

  .tip {
    border-top: 1px solid #e5e5e5;
    padding-top: 24px;
    color: #f5222d;
  }

  .already-footer {
    text-align: right;
    padding-bottom: 24px;
  }
}

.print-dialog {
  margin-top: -30px;
  padding: 0 24px;
  font-size: 14px;
  text-align: left;

  .print-content {
    border-bottom: 1px solid #e5e5e5;
    padding: 24px 0;

    div {
      padding-bottom: 12px;
    }

    span {
      color: #333333;
    }
  }

  .tip {
    color: #f5222d;
  }

  .print-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;
  }
}

.discount-wrap {
  margin-top: -30px;
  padding: 30px;

  ::v-deep .el-form-item__label {
    padding: 0;
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 30px;

    .el-input {
      width: 230px;
    }
  }

  ::v-deep .el-input-group__append {
    border: none;
  }

  .discount-footer {
    display: flex;
    padding-top: 10px;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.delete-wrap {
  margin-top: -30px;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-align: left;

  .message {
    font-size: 14px;
    padding-top: 20px;
    color: #666666;
  }

  .footer {
    display: flex;
    padding-top: 24px;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.setting {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 0px !important;
  }

  ::v-deep .el-tabs__item {
    margin-top: 0px !important;
  }
}

.red-invoice-remark {
  display: flex;
  width: 100%;
  flex-direction: column;

  .code-no {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    justify-content: space-between;

    div:last-child {
      flex: auto;
    }

    label {
      min-width: 0 !important;
      display: inline-block;
      margin: 15px 0;
    }
    .el-input {
      width: 158px;
    }
  }
  .el-input {
    width: 370px;
  }

  label:before {
    content: '*';
    color: #f5222d;
  }

  .red-area {
    padding-bottom: 15px;
    padding-left: 15px;
  }

  .hr {
    width: 100%;
    border-bottom: 1px solid #9d5224;
  }

  #textarea {
    color: #333333;
    width: auto;
    outline: none;
    resize: none;
  }
}
</style>

<style lang="scss">
.content-wrap {
  .scan-wait {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}
.button-wrap {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  align-items: center;
}
.el-tag--small .el-icon-close {
  vertical-align: super;
}
.scan_invoice_popover {
  position: relative;
  .cus_popover {
    position: absolute;
    top: 50px;
    width: 700px;
    background: red;
    z-index: 10;
    box-shadow: 0 2px 12px 0 #ebedf0;
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .cus_popover:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid #e4e7ed;
    border-color: transparent transparent #fff transparent;
    left: 40px;
    top: -20px;
  }
}
</style>